@use 'mediaQueries.modules.scss';


.thankyou_body{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    backdrop-filter: blur(5px);
    z-index: 50;
    .thankYou{
        display: flex;
        justify-content: space-between;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 15px;
    padding-bottom: 50px;
    h1{
        font-size: 50px;
        @include mediaQueries.for-tablet-landscape-up{
            font-size: 30px;
            text-align: center;
        }
        
        @include mediaQueries.for-phone-only{
            font-size: 25px;
            text-align: center;
        }
    }
    p{
        font-size: 20px;
        text-align: center;
        @include mediaQueries.for-tablet-landscape-up{
            font-size: 15px;
        }
        @include mediaQueries.for-phone-only{
            font-size: initial;
            margin-bottom: 50px;
        }
        
    }
    .button-container{
        height: 50px;
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mediaQueries.for-phone-only{
            width: 100%;
            flex-direction: column;
        }
        .btn{
            border-radius: 5px;
            @include mediaQueries.for-phone-only{
                margin-bottom: 20px;
            }
        }
        
    }
}
    }