$green: #4cb034;
@use 'mediaQueries.modules.scss';



.page-indicator{
    width: 100%;
    height: 30vh;
    overflow: hidden;
    box-sizing: border-box;
    // border: 0.5px solid transparent;
    position: relative;
    margin-bottom: 100px;
    @include mediaQueries.for-tablet-landscape-up{
        margin-bottom: 50px;
        height: 20vh;
    }
    @include mediaQueries.for-phone-only{
        margin-bottom: 50px;
        height: 30vh;
    }
    >.blur-bg{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        img{
            width: 120%;
            margin: -5px -10px -5px -10px;
        }
        // align-items: center;
        // // background-image: url('../img/indicator-BG.jpg');
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center;
        // filter: ;
        filter: blur(5px)  brightness(50%);
        // padding: 10px;
        // margin-right: -20px;

    }
    >.text-holder{
        padding: 0 100px;
        width: calc(100% - 200px);
        height: 100%;
        position: absolute;
        top: 0;
        // border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        @include mediaQueries.for-tablet-landscape-up{
            
        }
        @include mediaQueries.for-phone-only{
            padding: 0 20px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        h1{
            font-size: 50px;
            text-transform: capitalize;
            font-weight: 700;
            @include mediaQueries.for-phone-only{
                font-size: xx-large;
            }
        }
        a{
            color: white;
            font-size: large;
            text-transform: capitalize;
            font-weight: 600;
            text-decoration: none;
            @include mediaQueries.for-phone-only{
                font-size: small;
                font-weight: 400;
            }

        }
    }
}