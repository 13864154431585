@mixin for-phone-only {
    @media (max-width: 749px) {
        @content;
    }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 750px) {
        @content;
    }
}
@mixin for-tablet-landscape-up {
    @media (max-width: 1200px) {
        @content;
    }
}