 /* define primary colors */
 $primary_1: white;
 $primary_2: #b2ad7f;
 $text_color: #12131f;
 $primary_3: #687d80;
  $green: #4cb034;
  $greenFade: #4db03445;

 $default: white;
@use 'mediaQueries.modules.scss';


 @mixin link {
  text: {
    decoration: none;
    transform: capitalize;
  }
  color: $text_color;
  opacity: 0.6;
  font: {
    size: large;
  }
 }
 /* use the variables */
 .d-none{
  display: none;
  transition: 0.5s;

  &.nav-rep{
    display: block;
    height: 80px;
  transition: 0.5s;

  }
 }
 .navbar {
  background-color: $primary_1;
  display: flex;
  height: 80px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: initial;
  transition: 0.5s;
  // border: 1px solid #e1e2e4;
  &.sticky{
    position: fixed;
    transition: 0.5s;
    top: 0;
    height: 80px;
    z-index: 5;
    box-shadow: 1px 1px 15px rgba(166, 162, 162, 0.456);
    animation-name: nav;
    animation-fill-mode: forwards;
    // animation-delay: 0.1s;
    animation-duration: 0.5s;
    @include mediaQueries.for-phone-only{
      animation: none;
    }

    @keyframes nav {
      20%{
        top: -80px;
        opacity: 0.1;
      }
      100%{
        top: 0;
        opacity: 1;
      }
    }
    // box-shadow: 2px 6px 8px grey;
  }
  
  .logo-container{
    height: 100%;
    width: 375px;
    display: inherit;
    justify-content: center;
    align-items: inherit;
    @include mediaQueries.for-phone-only{
      // width: 200px;
      justify-content: flex-start;
      position: relative;
      left: 10px;
    }
    
    img{
      height: auto;
      width: 80px;
      font-size: large;
      text-transform: capitalize;
      @include mediaQueries.for-phone-only{
        width: 70px;
        height: auto;
      }
    }
    span{
      color: $green;
      position: center;
      left: 10px;
      font-size: large;
      font-weight: 800;
      font-family: monospace;
      bottom: -10px;
      @include mediaQueries.for-phone-only{
        font-size: small;
        left: 5px;
        font-family: monospace;
      }

    }
  }

  p{
    font-size: x-small;
    @include mediaQueries.for-tablet-landscape-up{
      // display: none;
      font-size: x-small;
    }
    @include mediaQueries.for-phone-only{
      display: none;
      
    }
  }

   >ul{
    list-style-type: none;
    display: flex;
    // border: 1px solid black;
    min-width: 400px;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    padding: {
      right: 100px;
    }
    margin: {
      // top: 0;
    }
    @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
      display: none;
    }
    &:nth-of-type(2){
      display: none;
      @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
        display: flex;
        min-width: 0;

      }
    }
    
    .menu-list li{
      @include mediaQueries.for-phone-only{
        display: none;

    }
      margin: 0 2px;
    :hover{
      // color: red;
      // font-size: large
    }
    a{
      @include link();
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .mobile-menu-list{
    display: none;
    @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
      display: block;

  }
    margin: 0 2px;
  :hover{
    color: red;
  }
  a{
    @include link();
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: larger;
  }
  }

  .navDropdown{
    background-color: transparent;
    border: none;
    font-size: 25px;
    display: none;
    cursor: pointer;
    @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
      display: block;
      position: absolute;
      right: 30px;
  }
  }

}

.navSidebar{
  display: none;
}

.sidebarMobileHide{
  display: none;
  @include mediaQueries.for-tablet-landscape-up{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-evenly;
    position: fixed;
    padding-top: 10px;
    // width: 100%;
    min-height: 100px;
    border-left: 1px solid $greenFade;
    border-bottom: 1px solid $greenFade;
    right: 0;
    top: 80px;
    overflow: hidden;
    animation-name: slideUpBase;
    animation-duration: 0.5s;
    transition: 0.5s;
    animation-fill-mode: forwards;
    ul{
      display: block;
      padding: 0;
      a, li{
          color: #000;
          text-decoration: none;
          padding: 10px 40px;
          border-radius: 10px;
          display: flex;
          width: 120px;
          position: relative;
          justify-content: center;
          align-items: center;

      }
    }
  }
}
  .sidebarMobileShow{
    display: none;
    @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-evenly;
        position: fixed;
        padding-top: 10px;
        // width: 100%;
        min-height: 100px;
        border-left: 1px solid $greenFade;
        border-bottom: 1px solid $greenFade;
        animation-name: slideDownBase;
        animation-duration: 0.5s;
        transition: 0.5s;
        animation-fill-mode: forwards;
        right: 0;
        top: 80px;
        overflow: hidden;
        ul{
          display: block;
          padding: 0;
          a, li{
              color: #000;
              text-decoration: none;
              padding: 10px 40px;
              border-radius: 10px;
              display: flex;
              width: 120px;
              position: relative;
              justify-content: center;
              align-items: center;
  
          }
        }

        @keyframes slideDownBase {
         from{
             z-index: 5;
             background-color: white;
             right: -200px;
            }
            to{
              right: 0;
              z-index: 5;
              background-color: rgba($color: #000000, $alpha: 0.4);
              background-color: white;

         }
        }
        @keyframes slideUpBase {
          from{
            // width: 200px;
            right: 0;
            z-index: 5;
            background-color: rgba($color: #000000, $alpha: 0.4);
            background-color: white;

       }
          to{
              // width: 0;
              right: -200px;
              z-index: 5;
              background-color: white;
             }
         }
    
}
}

   
 } 